
export default {
  components: {
    ImgCard: () => import("@/components/ImgCard"),
    Paragraph: () => import("@/components/Paragraph"),
    Parallex: () => import("@/components/Parallex"),
    CardInfomationStack: () => import("@/components/CardInfomationStack"),
    CardInfomationFull: () => import("@/components/CardInfomationFull"),
  },
  data() {
    return {
      video: null,
      card: null,
      img: null,
      lang: this.$router.currentRoute.params.lang,
      root: "facilities",
      customerTitle: null,
      customerLogo: null,
    };
  },
  metaInfo () {
    return {
      title: 'Facilities',
      titleTemplate: '%s | Kaewsamui resort',
      meta: [
        { name: 'description', content: 'Warm hotel, comfortable like at home. Located in Muang Suratthani Surrounded by the natural atmosphere of Khao Tha Phet' },
        { property: 'og:title', content: 'Facilities' },
        { property: 'og:site_name', content: 'Kaewsamui resort' },
        { property: 'og:type', content: 'website' },    
        { name: 'robots', content: 'index,follow' }
      ]
    }
  },
  mounted() {
    this.getAccommodation();
  },
  methods: {
    async getAccommodation() {
      const { results } = await this.$prismic.client.query([
        this.$prismic.Predicates.at("document.type", "meeting"),
        this.$prismic.Predicates.at("document.tags", [this.lang]),
      ]);
      this.video = results[0].data.video[0].text;
      console.log(results);
      this.card = results[0].data.card;
      this.img = results[0].data.body;
      this.customerTitle = results[0].data.customer_title;
      this.customerLogo = results[0].data.customer_list;
    },
  },
  watch: {
    $route() {
      this.lang = this.$router.currentRoute.params.lang;
      this.getAccommodation();
    },
  },
};
